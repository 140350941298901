import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialService } from './credential.service';
import { ProfilService } from './profil.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  //forms = [];
  credentials: any;

  @Output() credentialsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedCredentialFormEvent: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient, private credentialService: CredentialService, private profileService: ProfilService) { }

  getCredentials() {
    return this.credentials;
  }
  setCredentials(creds) {
    this.credentials = creds;
  }

  setSelectedForm(form) {
    this.selectedCredentialFormEvent.emit(form);
  }

  loadWallet(user, patient) {
    this.setCredentials({});

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    if (user.job === 'patient') {
/*      this.getForms().forEach(form => {
        if (form.enabledFor === user.job) {
          this.http.get('profilerapi/profile/' + form.name, httpOptions).subscribe((resp: any) => {
            this.getCredentials()[this.getFormIndex(form.name)] = JSON.parse(resp);
            this.credentialsEvent.emit(this.credentials);
          },
            error => {
              this.getCredentials()[this.getFormIndex(form.name)] = {};
              this.credentialsEvent.emit(this.credentials);
            });
        }
      });*/

      this.credentialService.getCredentials(user).subscribe((resp: any) => {
        let list = JSON.parse(resp).res;
        let credentialList = this.filterLastDocuments(list,"schema_name");

        for (let credential of credentialList) {
          let offer = credential.preview;
          this.initializeFormFromCredential(credential.schema_name, offer, credential.timestamps.updated);
        }
        this.credentialsEvent.emit(this.credentials);
      },
        error => {
          console.log(error);
        });
    } else {
      this.credentialService.getIssuedCredentials(user, patient).subscribe((resp: any) => {
        let list = JSON.parse(resp).res;
        let credentialList = this.filterLastDocuments(list,"schema_name");

        for (let credential of credentialList) {
          let offer = credential.preview;
          this.initializeFormFromCredential(credential.schema_name, offer, credential.timestamps.updated);
        }
        this.credentialsEvent.emit(this.credentials);
      },
        error => {
          console.log(error);
        });
    }
  }


  initializeFormFromCredential(schemaName, offer, date) {
    let index = this.getFormIndex(schemaName);
    this.getCredentials()[index] = {date:date};
    for (let i = 0; i < offer.attributes.length; i++) {
      var indexes = offer.attributes[i].name.split("_");
      if (this.getCredentials()[index][indexes[1]] == undefined)
        this.getCredentials()[index][indexes[1]] = {};
      this.getCredentials()[index][indexes[1]][indexes[2]] = JSON.parse(offer.attributes[i].value);
    }
  }

  getFormIndex(name) {
    let indexKey = "0";
    for (let k = 0; k < this.getForms().length; k++) {
      if (this.getForms()[k].name == name) {
        indexKey = this.getForms()[k].id;
        break;
      }
    }
    return indexKey;
  }

  getForms() {
    return this.profileService.getForms();
  }

  filterLastDocuments(list, filterKeyName):any {
    list.sort(function (a, b) {
      if (a.timestamps.updated < b.timestamps.updated)
        return 1;
      if (a.timestamps.updated > b.timestamps.updated)
        return -1;
      return 0;
    });

    let byKeys = {};
    for (let obj of list) {
      if (byKeys[obj[filterKeyName]] === undefined)
      byKeys[obj[filterKeyName]] = obj;
    }
    
    return Object.values(byKeys);
  }
}
