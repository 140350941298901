import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialService } from './credential.service';
import { ProfilService } from './profil.service';

@Injectable({
  providedIn: 'root'
})
export class TravelogueService {

  @Output() travelogueEvent: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient, private profilService: ProfilService) { }

  loadTravelogue(user, patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    let patientid = "none";
    if (patient !== undefined && patient !== null)
      patientid = patient.agentId;
      
    return this.http.get('profilerapi/profile/travelogue/'+patientid, httpOptions);
  }
}
