import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ProofsService } from '../proofs.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-proofs',
  templateUrl: './proofs.component.html',
  styleUrls: ['./proofs.component.css']
})
export class ProofsComponent implements OnInit {
  forms: any;
  profile: any;
  user: any;
  selectedForm: any = null;
  patient : any = null;

  constructor(private proofsService: ProofsService, private userService: UserService, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();

    this.profile = this.proofsService.getProofs();
    this.user = this.userService.getUser();
    this.patient = this.userService.getSelectedPatient();

    this.proofsService.proofsEvent.subscribe(prof => {
      this.spinnerService.hide();
      this.profile = prof;
      this.forms = this.proofsService.proofSchemas;
      this.forms.forEach(form => {
        if (this.profile[form.id] === undefined || JSON.stringify(this.profile[form.id]).indexOf("answer") === -1) {
            this.profile[form.id] = {};
          }
      });
    });

    this.proofsService.loadProofs(this.user, this.patient);

        
    this.proofsService.selectedProofFormEvent.subscribe(form => {
      this.selectedForm = form;
    });
  }

  setSelectedForm(form) {
    this.selectedForm = form;
  }

  enabled() {
    let b = this.userService.getUser().job == this.selectedForm.enabledFor;
    return b;
  }
}
