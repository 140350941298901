import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { ProfilThemeComponent } from '../profil-theme/profil-theme.component';
import { UserService } from '../user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

import { WalletService } from '../wallet.service';
import { ProofsService } from '../proofs.service';
import { ProfilService } from '../profil.service';

@Component({
  selector: 'app-form-summary',
  templateUrl: './form-summary.component.html',
  styleUrls: ['./form-summary.component.css'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),  // initial
        animate('1.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('1.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(0)', opacity: 0 }))
      ])
    ]),
    trigger('mouseinout', [
      state('in', style({
        transform: 'scale(1.02)',
        boxShadow: '0px 10px 5px 1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        backgroundColor: '#618dfd'
      })),
      state('out', style({
        transform: 'scale(1)'
      })),
      transition('out => in', [
        animate('0.5s')
      ]),
      transition('in => out', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class FormSummaryComponent implements OnInit {
  @Input() form: any;
  @Input() profileForm: any;
  @Input() isHeader: any;

  selectedTheme = null;
  firstname = "";
  lastname = "";
  isMouseIn = false;

  constructor(private profilService: ProfilService, private userService: UserService, private walletService: WalletService, private proofsService: ProofsService, public dialog: MatDialog, private _snackBar: MatSnackBar, public translate: TranslateService) { }

  ngOnInit() {
    if (this.profileForm === undefined)
      this.profileForm = {};
    this.form.themes.forEach(theme => {
      if (this.profileForm[theme.id] === undefined)
        this.profileForm[theme.id] = {};
    });

    if (this.userService.getUser().job == "patient") {
      this.firstname = this.userService.getUser().firstname;
      this.lastname = this.userService.getUser().lastname;
    }
  }

  categorySelected(category) {
    if (this.isViewable()) {
      if (this.profileForm[this.selectedTheme.id] === undefined)
        this.profileForm[this.selectedTheme.id] = {};
      this.dialog.open(ProfilThemeComponent, { data: { theme: this.selectedTheme, profilTheme: this.profileForm[this.selectedTheme.id], enabledForm: this.enabled(), selectedCategory: category }, height: '90%', width: '90%', panelClass: 'theme-dialog' });
    }
  }

  enabled() {
    let b = this.userService.getUser().job == this.form.enabledFor;
    return b;
  }

  isProfile() {
    return this.form.name == "general_information";
  }

  isCraDiagnostic() {
    return this.form.name == "diagnostic";
  }

  changeMouseIn(state) {
    this.isMouseIn = state;
  }
/*
  verifyCredential() {
    if (!this.isViewable() && this.userService.getUser().job !== 'patient') {
      this.spinnerService.show();
      let formsToVerify = [];
      for (let i = 0; i < this.profilService.getForms().length; i++) {
        if (this.profilService.getForms()[i].enabledFor !== this.userService.getUser().job) {
          formsToVerify.push(this.profilService.getForms()[i]);
        }
      }
      this.verifyService.verifyCredential(this.userService.getUser(), this.userService.getSelectedPatient(), [formsToVerify]).subscribe(
        resp => {
          this.spinnerService.hide();
          this._snackBar.open(this.translate.instant("formsummary.verifyasked"), "", {
            duration: 2000,
            verticalPosition: "top"
          });
        },
        error => {
          this.spinnerService.hide();
          this._snackBar.open(this.translate.instant("formsummary.verifyfailed"), "", {
            duration: 2000,
            verticalPosition: "top"
          });
        });
    }

  }*/

  showForm() {
    if (this.isViewable()) {
      this.walletService.setSelectedForm(null);
      this.proofsService.setSelectedForm(null);
    }
  }
  isViewable() {
    return (this.enabled()) || (JSON.stringify(this.profileForm).indexOf("answer") != -1)
  }
  getOrganizationLabel() {
    if (this.form.enabledFor !== undefined)
      return this.profilService.getOrganization(this.form.enabledFor).label[this.translate.currentLang];
    else
      return "";
  }

}
