<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1>
    {{theme.name[translate.currentLang]}}
</h1>
<div class="theme-container">
    <mat-tab-group (selectedTabChange)="scroll($event)" [selectedIndex]="selectedTab">
        <mat-tab [label]='getCategoryName(category)' *ngFor="let category of theme.categories">
        </mat-tab>
    </mat-tab-group>
    <div class="categories-container">
        <div class="categories-inner-container">
            <div class="category-container" id="{{i}}" *ngFor="let category of sortedCategories; index as i">
                <app-profil-category [category]="category" [profilCategory]="profilTheme[category.id]"
                    [enabledForm]="enabledForm"></app-profil-category>
            </div>
        </div>
    </div>
</div>
