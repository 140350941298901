import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css']
})
export class QrcodeComponent implements OnInit {

  public myAngularxQrCode: string = null;
  url = "";

  constructor(public dialogRef: MatDialogRef<QrcodeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public translate: TranslateService) { 
    console.log("Create QRCode dialog");
    this.url = data.res.url;
    this.myAngularxQrCode = data.res.url;
  }

  ngOnInit() {
    console.log("Init QRCode dialog");
  }

}
