import { Injectable, Output, EventEmitter } from '@angular/core';
import { VerifyService } from './verify.service';
import { OrganizationService } from './organization.service';
import { ProfilService } from './profil.service';
import { WalletService } from './wallet.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VerificationsService {
  verificationSchemas = [];
  verifications: any;
  @Output() verificationsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedVerificationsFormEvent: EventEmitter<any> = new EventEmitter();

  constructor(private profileService: ProfilService, private walletService: WalletService, private verifyService: VerifyService, private organizationService: OrganizationService) { }

  getVerifications() {
    return this.verifications;
  }
  setVerifications(verifs) {
    this.verifications = verifs;
  }

  setSelectedForm(form) {
    this.selectedVerificationsFormEvent.emit(form);
  }

  loadVerifications(user) {
    this.setVerifications({});
    this.verificationSchemas = [];

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    if (user.job === 'patient') {
      this.organizationService.getProofSchemas(user, "none").subscribe((resp: any) => {
        let schemas = JSON.parse(resp).res;

        this.verifyService.getPendingVerify(user).subscribe((resp: any) => {
          let list = JSON.parse(resp).res;
          for (let element of list) {
            element.proof_schema_id = element.proof_request.name + ":" + element.proof_request.version;
          }
          this.verifications = {};

          for (let verify of list) {
            let schema = verify.proof_request;
            for (let sc of schemas) {
              if (sc.name === schema.name) {
                schema.label = sc.label;
                break;
              }
            }
            let proofForm = this.createVerificationForm(schema, verify.id, verify.timestamps.updated);
            this.verificationSchemas.push(proofForm);
          }
          this.verificationsEvent.emit(this.verifications);
        },
          error => {
            console.log(error);
            this.verificationsEvent.emit(this.verifications);
          });
      },
      error => {
        console.log(error);
        this.verificationsEvent.emit(this.verifications);
      });
    }
  }


  getProofSchemaIndex(name) {
    let indexKey = null;
    for (let k = 0; k < this.verificationSchemas.length; k++) {
      if (this.verificationSchemas[k].name == name) {
        indexKey = this.verificationSchemas[k].id;
        break;
      }
    }
    return indexKey;
  }

  filterLastDocuments(list, filterKeyName): any {
    list.sort(function (a, b) {
      if (a.timestamps.updated < b.timestamps.updated)
        return 1;
      if (a.timestamps.updated > b.timestamps.updated)
        return -1;
      return 0;
    });

    let byKeys = {};
    for (let obj of list) {
      if (byKeys[obj[filterKeyName]] === undefined)
        byKeys[obj[filterKeyName]] = obj;
    }

    return Object.values(byKeys);
  }

  createVerificationForm(schema, verifyId, date) {
    let proofForm = { verifyId: verifyId, name: schema.name, version: schema.version, id: "" + this.verificationSchemas.length, formName: schema.label, themes: [], toAccept: true };
    let attributes = [];

    this.verifications[proofForm.id] = { date: date };

    let requestedAttributes = schema.requested_attributes;
    if (requestedAttributes.length === undefined)
      requestedAttributes = Object.values(requestedAttributes);

    for (let j = 0; j < requestedAttributes.length; j++) {
      if (requestedAttributes[j].names !== undefined)
        attributes = attributes.concat(requestedAttributes[j].names);
      else
        attributes = attributes.concat(requestedAttributes[j].name);
    }

    for (let j = 0; j < attributes.length; j++) {
      let ids = attributes[j].split('_');

      let form = null;
      for (let k = 0; k < this.getForms().length; k++) {
        if (this.getForms()[k].name == ids[0]) {
          form = this.getForms()[k];
          break;
        }
      }

      let formIndex = this.walletService.getFormIndex(ids[0]);
      let credential = this.walletService.getCredentials()[formIndex];
      if (this.verifications[proofForm.id][ids[0] + '_' + ids[1]] == undefined)
        this.verifications[proofForm.id][ids[0] + '_' + ids[1]] = {};

      if (credential !== undefined && credential[ids[1]] !== undefined && credential[ids[1]][ids[2]] !== undefined)
        this.verifications[proofForm.id][ids[0] + '_' + ids[1]][ids[2]] = credential[ids[1]][ids[2]];
      else
        this.verifications[proofForm.id][ids[0] + '_' + ids[1]][ids[2]] = {};

      let formTheme = null;
      for (let k = 0; k < form.themes.length; k++) {
        if (form.themes[k].id == ids[1]) {
          formTheme = form.themes[k];
          break;
        }
      }

      let theme = this.getTheme(proofForm, ids);
      if (theme == null) {
        theme = { id: ids[0] + '_' + ids[1], name: formTheme.name, categories: [], description: form.formName };
        proofForm.themes.push(theme);
      }
      let formCategory = null;
      for (let k = 0; k < formTheme.categories.length; k++) {
        if (formTheme.categories[k].id == ids[2]) {
          formCategory = formTheme.categories[k];
          break;
        }
      }
      theme.categories.push(formCategory);
    }
    return proofForm;
  }


  getTheme(form, ids) {
    for (let i = 0; i < form.themes.length; i++) {
      if (form.themes[i].id == ids[0] + '_' + ids[1])
        return form.themes[i];
    }
    return null;
  }

  getForms() {
    return this.profileService.getForms();
  }
}
