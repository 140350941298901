import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CredentialService {

  constructor(private http: HttpClient) { }


  issueCredential(user, patient, form, profileForm) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    var body = {
      organization : user.job,
      to: patient.to,
      credentialName: form.name,
      form: profileForm
    };
 /*
    for (var i = 0; i < forms.length; i++) {
      body[forms[i].name] = JSON.stringify(profileForms[forms[i].id]);
    }*/
    return this.http.post('credentialapi/credential', body, httpOptions);
  }
  
  getCredentials(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('credentialapi/credential', httpOptions);
  }


  getIssuedCredentials(user, patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('credentialapi/issuedCredential/'+patient.to, httpOptions);
  }


  getInboundCredentials(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('credentialapi/inboundCredential', httpOptions);
  }

  acceptCredential(user, credentialOffer) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    return this.http.put('credentialapi/credential', {cred: credentialOffer}, httpOptions);
  }


  rejectCredential(user, credentialOffer) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    return this.http.post('credentialapi/rejectCredential', {cred: credentialOffer}, httpOptions);
  }
}
