import { Component, OnInit } from '@angular/core';
import { ProfilService } from '../profil.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from '../organization.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  signupUser = {
    "firstname": "",
    "lastname": "",
    "email": "",
    "password": "",
    "job": "patient"
  };

  loginError = undefined;
  signupError = undefined;

  organizations = [];

  userName = "";
  password = "";
  constructor(private userService: UserService, private profilService: ProfilService, private router: Router, private spinnerService: NgxSpinnerService, public translate: TranslateService, private organizationService: OrganizationService) {
    translate.addLangs(['en', 'fr']);
    let lang = this.userService.getDefaultLanguage();
    translate.setDefaultLang(lang);
    translate.use(lang);
  }


  ngOnInit() {
    this.organizationService.getOrganizations().subscribe((resp: any) => {
      this.organizations = JSON.parse(resp).res;
      this.profilService.setOrganizations(this.organizations);

    },
      error => {
        console.log(error);
        if (error.error.error.message !== undefined)
          this.loginError = error.error.error.message;
        else
          this.loginError = error.error.error;
      });

    this.userService.signin(this.userName, this.password).subscribe(resp => {
      if (resp['token'])
        this.connected(resp);
    },
    error => {
        console.log(error);

    });

  }

  connect() {
    this.spinnerService.show();
    this.userService.signin(this.userName, this.password).subscribe(resp => {
      this.spinnerService.hide();
      this.connected(resp);
    },
      error => {
        console.log(error);
        this.spinnerService.hide();
        if (error.error.error.message !== undefined)
          this.loginError = error.error.error.message;
        else
          this.loginError = error.error.error;
      });
  }

  signup() {
    this.spinnerService.show();
    this.userService.signup(this.signupUser).subscribe((resp: any) => {
      this.spinnerService.hide();
      this.connected(resp)
    },
      error => {
        this.spinnerService.hide();
        if (error !== undefined && error.error !== undefined && error.error.error !== undefined) {
          if (error.error.error.message !== undefined)
            this.signupError = error.error.error.message;
          else if (error.error.error)
            this.signupError = error.error.error;
        }


      });
  }

  connected(user) {
    this.spinnerService.show();
    this.loginError = "";
    this.userService.setUser(user);

    this.organizationService.getForms(user).subscribe((resp: any) => {
      this.profilService.initForms(JSON.parse(resp).res.docs.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      }));
      this.spinnerService.hide();
      let usr: any = user;
      if (usr.job === 'patient') {
        this.router.navigate(['/patient']);
      } else {
        this.router.navigate(['/users']);
      }
    },
      error => {
        this.spinnerService.hide();
        if (error.error.error.message !== undefined)
          this.loginError = error.error.error.message;
        else
          this.loginError = error.error.error;
      })
  }

  getOrganizationLabel(org) {
    return org.label[this.translate.currentLang];
  }

  languageChanged(lang) {
    this.translate.use(lang);
    this.userService.setDefaultLanguage(lang);
  }
}
