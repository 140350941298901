import { Component, Input, OnChanges } from '@angular/core';
import { ProfilService } from '../profil.service';
import { DomSanitizer } from '@angular/platform-browser'
import {TranslateService} from '@ngx-translate/core';

import { WalletService } from '../wallet.service';

@Component({
  selector: 'app-profil-question',
  templateUrl: './profil-question.component.html',
  styleUrls: ['./profil-question.component.css']
})
export class ProfilQuestionComponent implements OnChanges {
  @Input() question: any;
  @Input() answer: any;
  @Input() enabledForm: any;

  sliderValue: any;
  pdfsource: any = "";

  profil: any;
  constructor(private walletService: WalletService, private sanitizer: DomSanitizer, public translate: TranslateService) {
  }

  ngOnChanges() {
    this.walletService.credentialsEvent.subscribe(prof => this.profil = prof);
    if (this.question.type == 'object') {
      this.question.structure.forEach(q => {
        if (this.answer[q.id] === undefined) {
          this.answer[q.id] = {};
        }
      });
    }
    if (this.question.type == 'pdffile') {
      if (this.answer.answer == undefined)
        this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl("");
      else
       this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(this.answer.answer);
    }

  }

  getSliderColor() {
    if (this.sliderValue === undefined) {
      if (this.answer.answer === undefined) {
        this.sliderValue = 0;
      } else {
        this.sliderValue = this.answer.answer;
      }
    }

    if (this.sliderValue <= 1)
      return "warn";
    if (this.sliderValue == 2)
      return "accent";

    return "primary";
  }

  getSliderLabel(value: number) {
    var sliderLabels = ["A","B","C","D","N/A"];
    return sliderLabels[value];
  }

  sliderMoved(event: any) {
    this.sliderValue = event.value;
  }

  loadFile() {
    const inputNode: any = document.querySelector('#file');
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (thefile: any) => {
      this.answer.answer = thefile.target.result;
      this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(this.answer.answer);
    };

    reader.readAsDataURL(inputNode.files[0]);

  }
}
