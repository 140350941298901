import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: any;

  selectedPatient: any = null;
  @Output() selectedPatientEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) { }

  getUser() {
    return this.user;
  }
  setUser(usr) {
    this.user = usr;
  }


  getSelectedPatient() {
    return this.selectedPatient;
  }
  setSelectedPatient(p) {
    this.selectedPatient = p;
    //this.selectedPatientEvent.emit(p);
  }

  signin(userName, password) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    var message =  { email:userName, password:password };
    return this.http.post('userapi/signin', message, httpOptions);
    //return this.http.get('userapi/signin',  httpOptions);
  }


  signup(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post('userapi/signup', user, httpOptions);
  }

  logout() {
    this.http.get('userapi/logout');
    this.user = {};
  }

  getPatients(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    }
    return this.http.get('userapi/patient', httpOptions)
  }


  getDefaultLanguage() {
    let l = localStorage.getItem('defaultLanguage');
    if (l === undefined || l === null)
      return 'en';
    return l;
  }


  setDefaultLanguage(l) {
   localStorage.setItem('defaultLanguage', l);
  }

  score(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Basic MGJlZjE3NmYtZGJjNC00N2E3LTgyNDgtMzJjNDIzOGExZDEyOnk5M0RJUlNyQzdKZER5eUZ2TnVWSW9aMUYzcVBCaU1qMURTWHFuRFNJSWRqcFFnd3JMd3ZBamxFMml3TFROR1U='
      })
    };
    return this.http.post('https://us-south.functions.cloud.ibm.com/api/v1/namespaces/Marc%20Yvon_GISC%20Noise/actions/TestAHR?blocking=true', {"data":JSON.stringify(data)}, httpOptions);
  }
}
