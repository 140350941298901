<mat-slide-toggle [disabled]="!enabledForm" color=primary matTooltip="{{question.name[translate.currentLang]}}"
  matTooltipShowDelay=2000 [(ngModel)]="answer.answer" *ngIf="question.type=='boolean'">
  {{question.name[translate.currentLang]}}
</mat-slide-toggle>

<mat-form-field *ngIf="question.type=='string'">
  <textarea [disabled]="!enabledForm" [(ngModel)]="answer.answer" matInput mat-autosize matAutosizeMaxRows="10"
    placeholder={{question.name[translate.currentLang]}}></textarea>
</mat-form-field><br>


<div *ngIf="question.type=='horizontal-radio-button'">
  <label matTooltip="{{question.name[translate.currentLang]}}">{{question.name[translate.currentLang]}}</label>
  <br>
  <mat-radio-group [disabled]="!enabledForm" [(ngModel)]="answer.answer">
    <mat-radio-button *ngFor="let choix of question.choix" value="{{choix.value}}" matTooltip="{{choix.name[translate.currentLang]}}">
      {{choix.name[translate.currentLang].substring(0,70)}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div *ngIf="question.type=='vertical-radio-button'">
  <label matTooltip="{{question.name[translate.currentLang]}}">{{question.name[translate.currentLang].substring(0,70)}}</label>
  <mat-radio-group class="vertical-radio-group" [disabled]="!enabledForm" [(ngModel)]="answer.answer">
    <mat-radio-button *ngFor="let choix of question.choix" value="{{choix.value}}" matTooltip="{{choix.name[translate.currentLang]}}">
      {{choix.name[translate.currentLang].substring(0,70)}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<mat-form-field *ngIf="question.type=='array'">
  <mat-label>{{question.name[translate.currentLang]}}</mat-label>
  <mat-select [disabled]="!enabledForm" [(value)]="answer.answer" matTooltip="{{question.name[translate.currentLang]}}">
    <mat-option>N/A</mat-option>
    <mat-option *ngFor="let choix of question.choix" value="{{choix.value}}"
      matTooltip="{{choix.name[translate.currentLang]}}">{{choix.name[translate.currentLang]}}</mat-option>
  </mat-select>
</mat-form-field>

<div class="object" *ngIf="question.type=='object'">
  <app-profil-question [question]="q" [answer]="answer[q.id]" [enabledForm]="enabledForm"
    *ngFor="let q of question.structure">
  </app-profil-question>
</div>

<div *ngIf="question.type=='pdffile'">
  <!--<button [disabled]="!enabledForm" type="button" mat-raised-button
    (click)="fileInput.click()">{{question.name[translate.currentLang]}}</button>-->
  <button mat-mini-fab color="primary" (click)="fileInput.click()" [disabled]="!enabledForm" matTooltip="{{question.name[translate.currentLang]}}" style="margin-bottom: 10px;">
      <mat-icon>attach_file</mat-icon>
  </button>
  <input hidden (change)="loadFile()" #fileInput type="file" id="file">
  <iframe id="pdfviewer" width="100%" height="600px" [src]="pdfsource"></iframe>
</div>

<div class="base4" *ngIf="question.type=='base4'">
  <span>{{question.name[translate.currentLang]}} - {{getSliderLabel(answer.answer)}}</span>
  <mat-slider [disabled]="!enabledForm" [color]="getSliderColor()" max="4" min="0" step="1" thumbLabel
    [displayWith]="getSliderLabel" tickInterval="1" [(ngModel)]="answer.answer" (input)="sliderMoved($event)">
  </mat-slider>
</div>


<div class="base5" *ngIf="question.type=='base5'">
  <span>{{question.name[translate.currentLang]}} - {{answer.answer}}</span>
  <mat-slider [disabled]="!enabledForm" [color]="getSliderColor()" max="5" min="1" step="1" thumbLabel tickInterval="1"
    [(ngModel)]="answer.answer" (input)="sliderMoved($event)">
  </mat-slider>
</div>