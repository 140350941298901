import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialService } from './credential.service';
import { VerifyService } from './verify.service';
import { OrganizationService } from './organization.service';

@Directive()
@Injectable({
  providedIn: 'root'
})

export class ProfilService {

  forms = [];
  organizations;
  /*
  credentials: any;

  @Output() credentialsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedCredentialFormEvent: EventEmitter<any> = new EventEmitter();


  proofSchemas = [];
  proofs: any;
  @Output() proofsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedProofFormEvent: EventEmitter<any> = new EventEmitter();
*/
  constructor(private http: HttpClient) {
  }

  /*
  getCredentials() {
    return this.credentials;
  }
  setCredentials(prof) {
    this.credentials = prof;
  }
*/

/*
  getProofs() {
    return this.proofs;
  }
  setProofs(prof) {
    this.proofs = prof;
  }
*/

initForms(f) {
  this.forms = f;
}
setOrganizations(orgs) {
  this.organizations = orgs;
}

getOrganization(name): any {
  for (let org of this.organizations) {
    if (org["_id"] === name)
      return org;
  }
  return null;
}
/*
  getFormIndex(name) {
    let indexKey = "0";
    for (let k = 0; k < this.getForms().length; k++) {
      if (this.getForms()[k].name == name) {
        indexKey = this.getForms()[k].id;
        break;
      }
    }
    return indexKey;
  }*/

  /*
  getProofSchemaIndex(name) {
    let indexKey = null;
    for (let k = 0; k < this.proofSchemas.length; k++) {
      if (this.proofSchemas[k].name == name) {
        indexKey = this.proofSchemas[k].id;
        break;
      }
    }
    return indexKey;
  }
*/
  /*
  loadWallet(user, patient) {
    this.setCredentials({});

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    if (user.job === 'patient') {
      this.forms.forEach(form => {
        if (form.enabledFor === user.job) {
          this.http.get('profilerapi/profile/' + form.name, httpOptions).subscribe((resp: any) => {
            this.getCredentials()[this.getFormIndex(form.name)] = JSON.parse(resp);
            this.credentialsEvent.emit(this.credentials);
          },
            error => {
              this.getCredentials()[this.getFormIndex(form.name)] = {};
              this.credentialsEvent.emit(this.credentials);
            });
        }
      });

      this.credentialService.getCredentials(user).subscribe((resp: any) => {
        let list = JSON.parse(resp).res;
        let credentialList = this.filterLastDocuments(list,"schema_name");

        for (let credential of credentialList) {
          let offer = credential.preview;
          this.initializeFormFromCredential(credential.schema_name, offer);
        }
        this.credentialsEvent.emit(this.credentials);
      },
        error => {
          console.log(error);
        });
    } else {
      this.credentialService.getIssuedCredentials(user, patient).subscribe((resp: any) => {
        let list = JSON.parse(resp).res;
        let credentialList = this.filterLastDocuments(list,"schema_name");

        for (let credential of credentialList) {
          let offer = credential.preview;
          this.initializeFormFromCredential(credential.schema_name, offer);
        }
        this.credentialsEvent.emit(this.credentials);
      },
        error => {
          console.log(error);
        });
    }
  }
*/

/*
  loadProofs(user, patient) {
    this.setProofs({});
    this.proofSchemas = [];

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    if (user.job === 'patient') {
      this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
        let list = JSON.parse(resp).res;
        for (let element of list) {
          element.proof_schema_id = element.proof_request.name+":"+element.proof_request.version;
        }
        let response = this.filterLastDocuments(list,"proof_schema_id");
        this.proofs = {};

        for (let verify of response) {
          let schema = verify.proof_request;
          let proofForm = this.createProofForm(schema);
          this.proofSchemas.push(proofForm);
        }
        for (let verify of response) {
          let index = this.getProofSchemaIndex(verify["proof_schema_id"].split(":")[0]);
          if (this.proofs[index] === undefined)
            this.proofs[index] = {};

          let attributes = verify["info"].attributes;

          let attributeIds = Object.keys(attributes);
          for (let j = 0; j < attributeIds.length; j++) {
            let attribute = attributes[attributeIds[j]];

            let ids = attribute.name.split("_");
            if (this.proofs[index][ids[0] + '_' + ids[1]] == undefined)
              this.proofs[index][ids[0] + '_' + ids[1]] = {};

            this.proofs[index][ids[0] + '_' + ids[1]][ids[2]] = JSON.parse(attribute.value);

          }
        }

        this.proofsEvent.emit(this.proofs);
      },
        error => {
          console.log(error);
          this.proofsEvent.emit(this.proofs);
        });
    } else {
      this.organizationService.getProofSchemas(user).subscribe((resp: any) => {
        let schemas = JSON.parse(resp).res.proofSchemas;
        for (let i = 0; i < schemas.length; i++) {
          let schema = schemas[i];
          let proofForm = this.createProofForm(schema);
          this.proofSchemas.push(proofForm);
        }
        this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
          let list = JSON.parse(resp).res;
          let response = this.filterLastDocuments(list,"proof_schema_id");
          this.proofs = {};

          for (let verify of response) {
            let index = this.getProofSchemaIndex(verify["proof_schema_id"].split(":")[0]);
            if (this.proofs[index] === undefined)
              this.proofs[index] = {};

            let attributes = verify["info"].attributes;

            let attributeIds = Object.keys(attributes);
            for (let j = 0; j < attributeIds.length; j++) {
              let attribute = attributes[attributeIds[j]];

              let ids = attribute.name.split("_");
              if (this.proofs[index][ids[0] + '_' + ids[1]] == undefined)
                this.proofs[index][ids[0] + '_' + ids[1]] = {};

              this.proofs[index][ids[0] + '_' + ids[1]][ids[2]] = JSON.parse(attribute.value);

            }
          }

          this.proofsEvent.emit(this.proofs);
        },
          error => {
            console.log(error);
            this.proofsEvent.emit(this.proofs);
          });
      }, error => {
        console.log("proofs schemas error");
        this.proofsEvent.emit(this.proofs);
      });
    }
  }
*/

/*
  filterLastDocuments(list, filterKeyName):any {
    list.sort(function (a, b) {
      if (a.timestamps.updated < b.timestamps.updated)
        return 1;
      if (a.timestamps.updated > b.timestamps.updated)
        return -1;
      return 0;
    });

    let byKeys = {};
    for (let obj of list) {
      if (byKeys[obj[filterKeyName]] === undefined)
      byKeys[obj[filterKeyName]] = obj;
    }
    
    return Object.values(byKeys);
  }

  */
/*
  createProofForm(schema) {
    let proofForm = { name: schema.name, version: schema.version, id: "" + this.proofSchemas.length, formName: { fr: schema.name, en: schema.name }, themes: [] };
    let attributes = [];

    let requestedAttributes = schema.requested_attributes;
    if (requestedAttributes.length === undefined)
      requestedAttributes=Object.values(requestedAttributes);

    for (let j = 0; j < requestedAttributes.length; j++) {
      if (requestedAttributes[j].names !== undefined)
        attributes = attributes.concat(requestedAttributes[j].names);
      else
        attributes = attributes.concat(requestedAttributes[j].name);
    }

    for (let j = 0; j < attributes.length; j++) {
      let ids = attributes[j].split('_');
      let form = null;
      for (let k = 0; k < this.getForms().length; k++) {
        if (this.getForms()[k].name == ids[0]) {
          form = this.getForms()[k];
          break;
        }
      }
      let formTheme = null;
      for (let k = 0; k < form.themes.length; k++) {
        if (form.themes[k].id == ids[1]) {
          formTheme = form.themes[k];
          break;
        }
      }

      let theme = this.getTheme(proofForm, ids);
      if (theme == null) {
        theme = { id: ids[0] + '_' + ids[1], name: formTheme.name, categories: [], description: form.formName };
        proofForm.themes.push(theme);
      }
      let formCategory = null;
      for (let k = 0; k < formTheme.categories.length; k++) {
        if (formTheme.categories[k].id == ids[2]) {
          formCategory = formTheme.categories[k];
          break;
        }
      }
      theme.categories.push(formCategory);
    }
    return proofForm;
  }
  */

  /*
  getTheme(form, ids) {
    for (let i = 0; i < form.themes.length; i++) {
      if (form.themes[i].id == ids[0] + '_' + ids[1])
        return form.themes[i];
    }
    return null;
  }
*/
  loadProfile2(user, patient) {
    /*    this.setProfile({});
    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          })
        };
    
        if (user.job === 'patient') {
          this.forms.forEach(form => {
            if (form.enabledFor === user.job) {
              this.http.get('profilerapi/profile/' + form.name, httpOptions).subscribe((resp: any) => {
                this.getProfile()[this.getFormIndex(form.name)] = JSON.parse(resp);
                this.profileEvent.emit(this.profile);
              },
                error => {
                  this.getProfile()[this.getFormIndex(form.name)] = {};
                  this.profileEvent.emit(this.profile);
                });
            }
          });
    
          this.credentialService.getCredentials(user).subscribe((resp: any) => {
            let credentials = JSON.parse(resp).res;
            credentials = credentials.sort(function (a, b) {
              if (a.timestamps.states.stored < b.timestamps.states.stored)
                return -1;
              if (a.timestamps.states.stored > b.timestamps.states.stored)
                return 1;
              return 0;
            });
    
            for (let i = 0; i < credentials.length; i++) {
              //          let offer = credentials[i].offer;
              let offer = credentials[i].preview;
              this.initializeFormFromCredential(credentials[i].schema_name, offer);
            }
            this.profileEvent.emit(this.profile);
          },
            error => {
              console.log(error);
            });
        } else {
          this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
            let attributes = JSON.parse(resp).res;
    
            for (let i = 0; i < attributes.length; i++) {
              let name = attributes[i].name;
              let indexes = name.split("_");
              let value = attributes[i].value;
              let index = this.getFormIndex(indexes[0]);
              if (this.getProfile()[index] == undefined)
                this.getProfile()[index] = {};
              if (this.getProfile()[index][indexes[1]] == undefined)
                this.getProfile()[index][indexes[1]] = {};
    
              this.getProfile()[index][indexes[1]][indexes[2]] = JSON.parse(value);
            }
            this.profileEvent.emit(this.profile);
          },
            error => {
              console.log(error);
            });
    
          this.credentialService.getIssuedCredentials(user, patient).subscribe((resp: any) => {
            let credentials = JSON.parse(resp).res;
            credentials = credentials.sort(function (a, b) {
              if (a.timestamps.updated < b.timestamps.updated)
                return -1;
              if (a.timestamps.updated > b.timestamps.updated)
                return 1;
              return 0;
            });
    
            for (let i = 0; i < credentials.length; i++) {
              //          let offer = credentials[i].offer;
              let offer = credentials[i].preview;
              this.initializeFormFromCredential(credentials[i].schema_name, offer);
            }
            this.profileEvent.emit(this.profile);
          },
            error => {
              console.log(error);
            });
        }*/
  }

  /*  initializeFormFromCredential(offer) {
      for (const attribute in offer.attributes) {
        let index = this.getFormIndex(attribute);
        this.getProfile()[index] = JSON.parse(offer.attributes[attribute]);
      }
    }
  */

    /*
  initializeFormFromCredential(schemaName, offer) {
    let index = this.getFormIndex(schemaName);
    this.getCredentials()[index] = {};
    for (let i = 0; i < offer.attributes.length; i++) {
      var indexes = offer.attributes[i].name.split("_");
      if (this.getCredentials()[index][indexes[1]] == undefined)
        this.getCredentials()[index][indexes[1]] = {};
      this.getCredentials()[index][indexes[1]][indexes[2]] = JSON.parse(offer.attributes[i].value);
    }
  }
*/

/*
  loadDefaultProfile() {
    this.setCredentials({ "2": {} });
    this.credentialsEvent.emit(this.credentials);
  }
*/
  /*
  loadVerifiedProfile(user, patient) {
    this.setProfile({});
    this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
      let attributes = JSON.parse(resp).res;

      for (let i = 0; i < attributes.length; i++) {
        let name = attributes[i].name;
        let indexes = name.split("_");
        let value = attributes[i].value;
        let index = this.getFormIndex(indexes[0]);
        if (this.getProfile()[index] == undefined)
          this.getProfile()[index] = {};
        if (this.getProfile()[index][indexes[1]] == undefined)
          this.getProfile()[index][indexes[1]] = {};

        this.getProfile()[index][indexes[1]][indexes[2]] = JSON.parse(value);
      }
      this.profileEvent.emit(this.profile);
    },
      error => {
        console.log(error);
      });
    this.profileEvent.emit(this.profile);
  }
*/
  getForms() {
    return this.forms;
  }

  getFormsToIssue(job) {
    return this.getForms().filter(form => form.enabledFor === job);
  }

  /*
  setSelectedForm(form) {
    this.selectedCredentialFormEvent.emit(form);
    this.selectedProofFormEvent.emit(form);
  }
*/
  saveProfile(user, form, profileForm) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    /*
        var apiName = "";
        if (form.name == "general_information")
          apiName = "generalInfo"
        if (form.name == "additional_data")
          apiName = "tsaInfo"
        if (form.name == "geva_sco")
          apiName = "gevascoInfo"
        if (form.name == "academic_skills")
          apiName = "compScoInfo"
    
        return this.http.post('profilerapi/' + apiName, profileForm, httpOptions);
        */

    return this.http.post('profilerapi/profile', { formName: form.name, profile: profileForm }, httpOptions);
  }

}
