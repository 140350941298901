import { Component, OnInit, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user.service';
import { ProfilService } from '../profil.service';
import { VerifyService } from '../verify.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.css']
})
export class ProfileSummaryComponent implements OnInit {
  @Input() profile:any;
  @Input() forms:any;

  patient: any;
  constructor(private userService: UserService, private verifyService: VerifyService, private profilService: ProfilService, public translate: TranslateService, private spinnerService: NgxSpinnerService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.patient = this.userService.getSelectedPatient();
  }

  getRowspan(index) {
    return ((parseInt('0110',2) >> (index%4)) & 1)+1;
  }


  verifyCredential() {
    if (this.userService.getUser().job !== 'patient') {
      this.spinnerService.show();
      let formsToVerify = [];
      for (let i = 0; i < this.profilService.getForms().length; i++) {
        if (this.profilService.getForms()[i].enabledFor !== this.userService.getUser().job) {
          formsToVerify.push(this.profilService.getForms()[i]);
        }
      }
      this.verifyService.verifyCredential(this.userService.getUser(), this.userService.getSelectedPatient(), [formsToVerify]).subscribe(
        resp => {
          this.spinnerService.hide();
          this._snackBar.open(this.translate.instant("formsummary.verifyasked"), "", {
            duration: 2000,
            verticalPosition: "top"
          });
        },
        error => {
          this.spinnerService.hide();
          this._snackBar.open(this.translate.instant("formsummary.verifyfailed"), "", {
            duration: 2000,
            verticalPosition: "top"
          });
        });
    }
  }
}
