import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {

  constructor(private http: HttpClient) { }

  verifyCredential(user, patient, schemaId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    var body = {
      credentialType : user.job,
      to: patient.to,
      schemaId:schemaId
    };

    return this.http.post('verifyapi/verify', body, httpOptions);
  }


  acceptVerify(user, verificationId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    var body = {id:verificationId};

    return this.http.patch('verifyapi/verify', body, httpOptions);
  }

  getPendingVerify(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('verifyapi/pendingVerify', httpOptions);
  }


  getVerified(user, patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    let to = ((patient === undefined || patient === null) ? "null":patient.to);
    return this.http.get('verifyapi/verify/'+to, httpOptions);
  }

  rejectVerify(user, verificationId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    var body = {
      id: verificationId
    };

    return this.http.post('verifyapi/rejectVerify', body, httpOptions);
  }

}
