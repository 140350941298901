import { Component, OnInit } from '@angular/core';
import { ProfilService } from '../profil.service';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { ConnectionService } from '../connection.service';
import { UserService } from '../user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {QrcodeComponent} from '../qrcode/qrcode.component'

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], term): any {
    return term
      ? items.filter(item => (item.firstname + item.lastname).toLowerCase().indexOf(term.toLowerCase()) !== -1)
      : items;
  }
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  user: any;
  patientList = [];
  term = "";

  constructor(private profilService: ProfilService, private userService: UserService, private connectionService: ConnectionService, public dialog: MatDialog,
    private router: Router, private _snackBar: MatSnackBar, private spinnerService: NgxSpinnerService, public translate: TranslateService) { }

  ngOnInit() {
    this.spinnerService.show();
    this.userService.getPatients(this.userService.getUser()).subscribe((resp: any) => {
      console.log(resp);
      this.patientList = JSON.parse(resp);
      this.mapConnectedPatients();
    },
    error => {
        console.log(error);
        this.spinnerService.hide();
    });
  }

  mapConnectedPatients() {
    this.connectionService.getConnectedPatients(this.userService.getUser()).subscribe((connected:any) => {
      var connectedList = JSON.parse(connected).res;
      this.patientList.map(obj=> {
          var connectedIndex = connectedList.findIndex(i => i.agentId === obj.agentId);
          var c = ( connectedIndex != -1);
          obj.connected = c;
          if (connectedIndex !== -1){
            obj.to = connectedList[connectedIndex].to;
          }
          return obj;
      });

      this.spinnerService.hide();
    }, connectedError => {
      console.log(connectedError);
      this.spinnerService.hide();
    });
  }

  setSelectedPatient(patient) {
    this.userService.setSelectedPatient(patient);
    if (patient.connected)
        this.router.navigate(['/patient'], {state:patient});
  }

  connectToSelectedPatient() {
    this.connectionService.createConnectionOffer(this.userService.getUser(), this.userService.getSelectedPatient()).subscribe(
      resp => {
        this._snackBar.open(this.translate.instant("userlist.envoieconnexion"), "", {
          duration: 2000
        });
        const dialogRef = this.dialog.open(QrcodeComponent, { data: resp });
        dialogRef.afterClosed().subscribe(result => {
          this.mapConnectedPatients();
        });
      },
      error => {
        this._snackBar.open(this.translate.instant("userlist.erreurconnexion"), "", {
          duration: 2000
        });
      });
  }

  isSelectedPatient(patient) {
    return patient == this.userService.getSelectedPatient();
  }
  getSelectedPatient() {
    return this.userService.getSelectedPatient();
  }
}
