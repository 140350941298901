<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title *ngIf="connectionOffers.length > 0">{{ "alert.connectionoffer" | translate }}</h1>
<div *ngFor="let connectionOffer of connectionOffers">
  {{ "alert.connectionoffersentence" | translate:connectionOffer }}<br>
  {{ "alert.doyouwanttoaccept" | translate }}
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="acceptConnectionOffer(connectionOffer)">{{ "alert.accept" | translate }}</button>
    <button mat-button (click)="rejectConnectionOffer(connectionOffer)">{{ "alert.reject" | translate }}</button>
  </div>
</div>
<h1 mat-dialog-title *ngIf="credentialOffers.length > 0">{{ "alert.credentialoffer" | translate }}</h1>
<div *ngFor="let credentialOffer of credentialOffers">
  {{ "alert.credentialoffersentence" | translate:getCredentialOfferLabels(credentialOffer) }}<br>
  {{ "alert.doyouwanttoaccept" | translate }}
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="acceptCredentialOffer(credentialOffer)">{{ "alert.accept" | translate }}</button>
    <button mat-button (click)="rejectCredentialOffer(credentialOffer)">{{ "alert.reject" | translate }}</button>
  </div> 
</div>

<!--
<h1 mat-dialog-title *ngIf="verifyDemands.length > 0">{{ "alert.verificationask" | translate }}</h1>
<div *ngFor="let verifyDemand of verifyDemands">
  {{ "alert.verificationasksentence" | translate:verifyDemand }}<br>
    <div class="row">
      <div class="col-sm" *ngFor="let attribute of getVerifyAttibutes(verifyDemand)">
        <mat-slide-toggle [(ngModel)]="verifyDemandsAttributes[verifyDemand.id][attribute]" color=primary>
          {{getFormLabel(attribute)}}
        </mat-slide-toggle>
      </div> 
    </div>

  {{ "alert.doyouwanttoaccept" | translate }}
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="acceptVerifyDemand(verifyDemand)">{{ "alert.accept" | translate }}</button>
    <button mat-button (click)="rejectVerifyDemand(verifyDemand)">{{ "alert.reject" | translate }}</button>
  </div>
</div>
-->