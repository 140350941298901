import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { VerifyService } from './verify.service';
import { OrganizationService } from './organization.service';
import { ProfilService } from './profil.service';

@Injectable({
  providedIn: 'root'
})
export class ProofsService {
  proofSchemas = [];
  proofs: any;
  @Output() proofsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedProofFormEvent: EventEmitter<any> = new EventEmitter();

  constructor(private profileService: ProfilService, private verifyService: VerifyService, private organizationService: OrganizationService) { }

  getProofs() {
    return this.proofs;
  }
  setProofs(prof) {
    this.proofs = prof;
  }

  setSelectedForm(form) {
    this.selectedProofFormEvent.emit(form);
  }

  loadProofs(user, patient) {
    this.setProofs({});
    this.proofSchemas = [];

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    if (user.job === 'patient') {
      this.organizationService.getProofSchemas(user, "none").subscribe((resp: any) => {
        let schemas = JSON.parse(resp).res;
        this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
          let list = JSON.parse(resp).res;
          for (let element of list) {
            element.proof_schema_id = element.proof_request.name + ":" + element.proof_request.version;
          }
          let response = this.filterLastDocuments(list, "proof_schema_id");
          this.proofs = {};

          for (let verify of response) {
            let schema = verify.proof_request;
            let label = {};
            for (let sc of schemas) {
              if (sc.name === schema.name) {
                schema.label = sc.label;
                break;
              }
            }
            
            let proofForm = this.createProofForm(schema);
            proofForm["enabledFor"] = verify.connection.remote.name;
            this.proofSchemas.push(proofForm);
          }
          for (let verify of response) {
            let index = this.getProofSchemaIndex(verify["proof_schema_id"].split(":")[0]);
            if (this.proofs[index] === undefined)
              this.proofs[index] = { date: verify.timestamps.updated };

            let attributes = verify["info"].attributes;

            let attributeIds = Object.keys(attributes);
            for (let j = 0; j < attributeIds.length; j++) {
              let attribute = attributes[attributeIds[j]];

              let ids = attribute.name.split("_");
              if (this.proofs[index][ids[0] + '_' + ids[1]] == undefined)
                this.proofs[index][ids[0] + '_' + ids[1]] = {};

              this.proofs[index][ids[0] + '_' + ids[1]][ids[2]] = JSON.parse(attribute.value);

            }
          }

          this.proofsEvent.emit(this.proofs);
        },
          error => {
            console.log(error);
            this.proofsEvent.emit(this.proofs);
          });
      },
      error => {
        console.log(error);
        this.proofsEvent.emit(this.proofs);
      });

    } else {
      this.organizationService.getProofSchemas(user, user.job).subscribe((resp: any) => {
        let schemas = JSON.parse(resp).res.proofSchemas;
        for (let i = 0; i < schemas.length; i++) {
          let schema = schemas[i];
          let proofForm = this.createProofForm(schema);
          this.proofSchemas.push(proofForm);
        }
        this.verifyService.getVerified(user, patient).subscribe((resp: any) => {
          let list = JSON.parse(resp).res;
          let response = this.filterLastDocuments(list, "proof_schema_id");
          this.proofs = {};

          for (let verify of response) {
            let index = this.getProofSchemaIndex(verify["proof_schema_id"].split(":")[0]);
            if (this.proofs[index] === undefined)
              this.proofs[index] = { date: verify.timestamps.updated };

            let attributes = verify["info"].attributes;

            let attributeIds = Object.keys(attributes);
            for (let j = 0; j < attributeIds.length; j++) {
              let attribute = attributes[attributeIds[j]];

              let ids = attribute.name.split("_");
              if (this.proofs[index][ids[0] + '_' + ids[1]] == undefined)
                this.proofs[index][ids[0] + '_' + ids[1]] = {};

              this.proofs[index][ids[0] + '_' + ids[1]][ids[2]] = JSON.parse(attribute.value);

            }
          }

          this.proofsEvent.emit(this.proofs);
        },
          error => {
            console.log(error);
            this.proofsEvent.emit(this.proofs);
          });
      }, error => {
        console.log("proofs schemas error");
        this.proofsEvent.emit(this.proofs);
      });
    }
  }


  getProofSchemaIndex(name) {
    let indexKey = null;
    for (let k = 0; k < this.proofSchemas.length; k++) {
      if (this.proofSchemas[k].name == name) {
        indexKey = this.proofSchemas[k].id;
        break;
      }
    }
    return indexKey;
  }

  filterLastDocuments(list, filterKeyName): any {
    list.sort(function (a, b) {
      if (a.timestamps.updated < b.timestamps.updated)
        return 1;
      if (a.timestamps.updated > b.timestamps.updated)
        return -1;
      return 0;
    });

    let byKeys = {};
    for (let obj of list) {
      if (byKeys[obj[filterKeyName]] === undefined)
        byKeys[obj[filterKeyName]] = obj;
    }

    return Object.values(byKeys);
  }

  createProofForm(schema) {
    let proofForm = { name: schema.name, version: schema.version, id: "" + this.proofSchemas.length, formName: schema.label, themes: [] };
    let attributes = [];

    let requestedAttributes = schema.requested_attributes;
    if (requestedAttributes.length === undefined)
      requestedAttributes = Object.values(requestedAttributes);

    for (let j = 0; j < requestedAttributes.length; j++) {
      if (requestedAttributes[j].names !== undefined)
        attributes = attributes.concat(requestedAttributes[j].names);
      else
        attributes = attributes.concat(requestedAttributes[j].name);
    }

    for (let j = 0; j < attributes.length; j++) {
      let ids = attributes[j].split('_');
      let form = null;
      for (let k = 0; k < this.getForms().length; k++) {
        if (this.getForms()[k].name == ids[0]) {
          form = this.getForms()[k];
          break;
        }
      }
      let formTheme = null;
      for (let k = 0; k < form.themes.length; k++) {
        if (form.themes[k].id == ids[1]) {
          formTheme = form.themes[k];
          break;
        }
      }

      let theme = this.getTheme(proofForm, ids);
      if (theme == null) {
        theme = { id: ids[0] + '_' + ids[1], name: formTheme.name, categories: [], description: form.formName };
        proofForm.themes.push(theme);
      }
      let formCategory = null;
      for (let k = 0; k < formTheme.categories.length; k++) {
        if (formTheme.categories[k].id == ids[2]) {
          formCategory = formTheme.categories[k];
          break;
        }
      }
      theme.categories.push(formCategory);
    }
    return proofForm;
  }


  getTheme(form, ids) {
    for (let i = 0; i < form.themes.length; i++) {
      if (form.themes[i].id == ids[0] + '_' + ids[1])
        return form.themes[i];
    }
    return null;
  }

  getForms() {
    return this.profileService.getForms();
  }
}
