<div class="patientsList">
  <h1 mat-subheader>{{ "userlist.choose" | translate }}</h1>
  <div class="center-div">


    <mat-form-field appearance="outline">
      <input matInput name="term" [(ngModel)]="term" [placeholder]="'userlist.recherche' | translate ">
    </mat-form-field>

    <div class="patientsContainer">
      <div class="menu-inner-container">
        <div [ngClass]="{'patient-selected': isSelectedPatient(patient)}" class="patient"
          *ngFor="let patient of patientList | filter : term">
          <mat-icon *ngIf="!patient.connected" class="user-locked">lock</mat-icon>
          <a mat-button (click)="setSelectedPatient(patient)">
            <!--           <img src="../../assets/pictos/EMOTIONS.svg">-->
            <mat-icon class="blue-icon">connect_without_contact</mat-icon>
          </a>
          <p class="userName">
            {{patient.firstname}} {{patient.lastname}}
          </p>
        </div>
      </div>
    </div>

    <button mat-button style="height: 90px;" [disabled]="getSelectedPatient() == null"
      (click)="connectToSelectedPatient()">
      {{ "userlist.connexion" | translate }}
    </button>
  </div>
  <h1> </h1>
</div>

<!--
<mat-nav-list>
  <mat-list-item *ngFor="let patient of patientList" (click)="patientSelected(patient)">
    <mat-icon mat-list-icon>contacts</mat-icon>
    <h4 mat-line>{{patient.user.given_name}}</h4>
  </mat-list-item>
</mat-nav-list>
-->