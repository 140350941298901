import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfilService } from '../profil.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profil-category',
  templateUrl: './profil-category.component.html',
  styleUrls: ['./profil-category.component.css']
})
export class ProfilCategoryComponent implements OnChanges {
  @Input() category: any;
  @Input() profilCategory: any;
  @Input() enabledForm: any;

  //mouseOverHeader:boolean;

  constructor(public dialog: MatDialog,public translate: TranslateService) {
   }

  ngOnChanges() {
    this.category.questions.forEach(question => {
      if (this.profilCategory[question.id] === undefined) {
        this.profilCategory[question.id] = {};
      }
    });
  }
}
