<div *ngIf="selectedForm==null">
    <div class="row">
        <div class="col-11">
            <h1>
                <span *ngIf="patient == null">{{ "wallet.wallettitle" | translate }}</span>
                <span *ngIf="patient != null">{{ "wallet.wallettitle2" | translate }} {{patient.firstname}} {{patient.lastname}}</span>
                <!--{{ "profilsummary.profil" | translate }} <span *ngIf="patient !== null">- {{patient.firstname}} {{patient.lastname}}</span>-->
            </h1>
        </div>
    </div>

    <mat-grid-list cols="2" rowHeight="250px">
        <div *ngFor="let form of forms; let index = index;">
            <mat-grid-tile *ngIf="isViewable(profile[forms[index].id], form)">
                <app-form-summary  (click)="setSelectedForm(form)" [form]=form [profileForm]=profile[forms[index].id]>
                </app-form-summary>
            </mat-grid-tile>
        </div>
    </mat-grid-list>
</div>

<app-profil-form *ngIf="selectedForm!=null" [form]="selectedForm" [profileForm]="profile[selectedForm.id]" [enabledForm]="enabled()">
</app-profil-form> 