import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
const routes: Routes = [
  {
    path: 'users',
    component: UserListComponent
  },   
  {
    path: 'home',
    component: HomeComponent
  }, 
  {
    path: 'patient',
    component: MainComponent
  },
  {
    path: 'cra',
    component: MainComponent
  },
  {
    path: 'cio',  
    component: MainComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
