import { Component, OnInit } from '@angular/core';
import { WalletService } from '../wallet.service';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
/*  @Input() profile:any;
  @Input() forms:any;*/
  forms: any;
  profile: any;
  user: any;
  selectedForm: any = null;
  connectionOffers: any = [];
  credentialOffers: any = [];
  verifyDemands: any = [];

  patient : any = null;
  constructor(private walletService: WalletService, private userService: UserService, private spinnerService: NgxSpinnerService, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
    //this.spinnerService.show();
    this.profile = this.walletService.getCredentials();
    this.user = this.userService.getUser();
    this.patient = this.userService.getSelectedPatient();
    //    this.loadVerifiedProfile();
/*
    this.walletService.credentialsEvent.subscribe(prof => {
      this.spinnerService.hide();
      this.profile = prof;
      this.forms = this.walletService.getForms();
    });

    this.walletService.loadWallet(this.user, this.patient);
    */
    this.profile = this.walletService.getCredentials();
    this.forms = this.walletService.getForms();


    this.walletService.selectedCredentialFormEvent.subscribe(form => {
      this.selectedForm = form;
    });
  }

  
  isViewable(prof, form) {
 /*   if (profileForm !== undefined && this.patient === null) {
        return (JSON.stringify(profileForm).indexOf("answer") != -1);
    } else {
      return (this.userService.getUser().job == form.enabledFor);
    }*/

    if (this.patient !== null) {
      return (this.userService.getUser().job == form.enabledFor);
    }
    else {

      return (prof!==undefined && JSON.stringify(prof).indexOf("answer") != -1 && form.enabledFor !== "patient")
    }

  }

  setSelectedForm(form) {
    if (this.profile[form.id] === undefined || JSON.stringify(this.profile[form.id]).indexOf("answer") === -1) {
      if (this.user.job == form.enabledFor && form.copyfrom !== undefined && this.profile[form.copyfrom] !== undefined && Object.keys(this.profile[form.copyfrom]).length !== 0) { 
        // Rustine pour use case Rectorat => GevaSco
        this.profile[form.id] = this.profile[form.copyfrom];
      } else {
        this.profile[form.id] = {};
      }
    }

    this.selectedForm = form;
  }

  enabled() {
    let b = this.userService.getUser().job == this.selectedForm.enabledFor;
    return b;
  }
}
