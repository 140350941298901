import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ConnectionService } from '../connection.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertsComponent } from '../alerts/alerts.component';
import { MatDialog } from '@angular/material/dialog';
import { CredentialService } from '../credential.service';
import { VerifyService } from '../verify.service';
import { TranslateService } from '@ngx-translate/core';

import { WalletService } from '../wallet.service';
import { ProofsService } from '../proofs.service';
import { TravelogueService } from '../travelogue.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VerificationsService } from '../verifications.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  travelogueForm: any = null;
  travelogue;

  connectionOffers: any = [];
  credentialOffers: any = [];
  verifyDemands: any = [];
  page = -1;
  constructor(private travelogueService: TravelogueService, private userService: UserService, private connectionService: ConnectionService, private verificationsService: VerificationsService, private spinnerService: NgxSpinnerService, 
    private credentialService: CredentialService, private verifyService: VerifyService, private walletService: WalletService, private proofsService: ProofsService, private router: Router, private _location: Location, public dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit() {
    /*
    this.spinnerService.show();
    this.profile = this.profilService.getProfile();
    this.user = this.userService.getUser();
    this.patient = this.userService.getSelectedPatient();
    //    this.loadVerifiedProfile();

    this.profilService.profileEvent.subscribe(prof => {
      this.spinnerService.hide();
      this.profile = prof;
      this.forms = this.profilService.getForms();
      //console.log("*********");
      //console.log(JSON.stringify(prof));
      this.forms.forEach(form => {
        if (this.profile[form.id] === undefined || JSON.stringify(this.profile[form.id]).indexOf("answer") === -1) {
          if (this.user.job == form.enabledFor && form.copyfrom !== undefined && this.profile[form.copyfrom] !== undefined && Object.keys(this.profile[form.copyfrom]).length !== 0) { // Rustine pour use case Rectorat => GevaSco
            //console.log("ici : " +form.id);
            this.profile[form.id] = this.profile[form.copyfrom];
          } else {
              //console.log("là : " +form.id);
            this.profile[form.id] = {};
          }
        } else {
          //console.log("coucou : " +form.id);
          //console.log(this.profile[form.id]);
        }
      });
    });

    this.profilService.loadProfile(this.user, this.patient);

    this.profilService.selectedFormEvent.subscribe(form => {
      this.selectedForm = form;
    });
*/


    this.spinnerService.show();
    this.walletService.credentialsEvent.subscribe(prof => {
      this.spinnerService.hide();
    });

    this.walletService.loadWallet(this.userService.getUser(), this.userService.getSelectedPatient());

    this.walletService.getForms().forEach(form => {
      if (form.name == 'travelogue')
        this.travelogueForm = form;
    });

    this.travelogueService.loadTravelogue(this.userService.getUser(), this.userService.getSelectedPatient()).subscribe((resp: any) => {
      this.travelogue = JSON.parse(resp);
    },
      error => {
        this.travelogue = {};
      });

    this.connectionService.getConnectionProposal(this.userService.getUser()).subscribe(
      (resp: any) => {
        this.connectionOffers = JSON.parse(resp).res;
      },
      error => {
        console.log("Connection proposals Error: ");
        console.log(error);
      });

    this.credentialService.getInboundCredentials(this.userService.getUser()).subscribe(
      (resp: any) => {
        this.credentialOffers = JSON.parse(resp).res;
      },
      error => {
        console.log("Credential proposals Error: ");
        console.log(error);
      });

    this.verifyService.getPendingVerify(this.userService.getUser()).subscribe(
      (resp: any) => {
        this.verifyDemands = JSON.parse(resp).res;
      },
      error => {
        console.log("Verify proposals Error: ");
        console.log(error);
      });
  }

  changePage(i) {
    this.walletService.setSelectedForm(null);
    this.proofsService.setSelectedForm(null);
    this.verificationsService.setSelectedForm(null);
    this.page = i;
  }

  enabled() {
    return this.userService.getSelectedPatient() === null;
  }

  /*
  isViewable(form) {
    if (form == null)
      return true;
    return (this.userService.getUser().job == form.enabledFor) || (JSON.stringify(this.profile[form.id]).indexOf("answer") != -1)
  }

  setSelectedForm(form) {
    if (this.isViewable(form))
      this.selectedForm = form;
  }
*/
  showAlerts(event) {
    if (this.getAlertNumber())
      this.dialog.open(AlertsComponent, { data: { connectionOffers: this.connectionOffers, credentialOffers: this.credentialOffers, verifyDemands: this.verifyDemands }, maxHeight: 'calc(100vh - ' + event.y + 'px)', height: 'auto', width: '50%', position: { left: event.x + 'px', top: event.y + 'px' }, panelClass: 'alerts-dialog' });
  }

  getAlertNumber() {
    return this.connectionOffers.length + this.credentialOffers.length;
  }


  getVerificationsNumber() {
    if (this.verificationsService.getVerifications() == undefined || this.verificationsService.getVerifications() == null)
      return this.verifyDemands.length;
    else
      return Object.keys(this.verificationsService.getVerifications()).length;
  }

  logout() {
    this.userService.setSelectedPatient(null);
    //    this.router.navigate(['/home']);
  }

  back() {
    this._location.back();
  }

  showUsers() {
    this.router.navigate(['/users']);
  }

  isPatient() {
    return this.userService.getUser().job == "patient";
  }

  getFormname(form) {
    return form.formName[this.translate.currentLang];
  }
}
