<div class="row">
    <div class="col-11">
        <h1>
            {{ "profilsummary.profil" | translate }} <span *ngIf="patient !== null">- {{patient.firstname}} {{patient.lastname}}</span>
        </h1>
    </div>
    <div class="col-1">
        <button mat-fab *ngIf="patient !== null" (click)="verifyCredential()" color="primary">
            <img src="../../assets/pictos/refresh.png" style="height: 30px;">
        </button>
    </div>
</div>

<mat-grid-list cols="2" rowHeight="250px">
    <mat-grid-tile [colspan]="1" [rowspan]="getRowspan(index)" *ngFor="let form of forms; let index = index;">
        <app-form-summary [isHeader]="getRowspan(index)==2" [form]=form [profileForm]=profile[forms[index].id]>
        </app-form-summary>
    </mat-grid-tile>
</mat-grid-list>