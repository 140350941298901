<mat-card @items [@mouseinout]="isMouseIn ? 'in' : 'out'" (mouseenter)="changeMouseIn(true)" (mouseleave)="changeMouseIn(false)">
    <mat-card-content ngClass="withoutHeader">
        <div class="profile-content">
            <div class="center-div">
                <h1> 
                    {{form.formName[translate.currentLang]}}
                </h1>
                <h4>
                    {{getOrganizationLabel()}}
                </h4>
                <h5>{{profileForm.date*1000 | date:'mediumDate'}}</h5>
              </div>
        </div>
    </mat-card-content>
</mat-card>

<!--
<mat-card @items [ngClass]="{'blurCard':!isViewable()}" (click)="verifyCredential()">
    <div class="card-header" *ngIf="isHeader">
        <mat-card-title>
            {{form.formName[translate.currentLang]}}
        </mat-card-title>
        <div id="menuContainer">
            <div id="menu-inner-container">
                <a mat-icon-button [ngClass]="{'selected':theme === selectedTheme}" *ngFor="let theme of form.themes"
                    matTooltip="{{theme.name[translate.currentLang]}}" (click)="selectedTheme = theme;">
                    <mat-icon *ngIf="theme.picto == undefined">bookmark</mat-icon>
                    <img *ngIf="theme.picto !== undefined" src="../../assets/pictos/{{theme.picto}}.svg">
                </a>
            </div>
        </div>

    </div>
    <mat-card-content [ngClass]="{'withoutHeader':!isHeader}">
        <div *ngIf="selectedTheme !== null">
            <div class="category-title">
                {{selectedTheme.name[translate.currentLang]}}
            </div>
            <mat-nav-list>
                <mat-list-item *ngFor="let category of selectedTheme.categories" (click)="categorySelected(category)">
                    {{category.name[translate.currentLang]}}
                </mat-list-item>
            </mat-nav-list>
        </div>

        <div class="profile-content" *ngIf="isProfile()">
            <div class="row h-100" style="margin: 0px;">
                <div class="col-3 my-auto ">
                    <div class="profile-picture">                
                        <img src="../../assets/pictos/EMOTIONS.svg">
                    </div>
                </div>
                <div class="col-4 my-auto">
                    <button mat-button>
                        {{ "formsummary.changerphoto" | translate }}
                    </button>
                    <button mat-button (click)="showForm()">
                        {{ "formsummary.editerprofil" | translate }}
                    </button>
                </div>
                <div class="col-5 my-auto white-font">
                    <mat-form-field appearance="outline">
                        <input matInput readonly value="{{firstname}}">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput readonly value="{{lastname}}">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="profile-content" *ngIf="isCraDiagnostic()">
            <div class="document center-div">
                <a mat-icon-button (click)="showForm()">
                  <img src="../../assets/pictos/edit.svg">
                </a>
                <p>
                    {{ "formsummary.diagnostic" | translate }}
                </p>
              </div>
        </div>
        <div class="profile-content" *ngIf="!isCraDiagnostic() && !isProfile() && !isHeader">
            <div class="center-div"  (click)="showForm()">
                <h1> 
                    {{form.formName[translate.currentLang]}}
                </h1>
              </div>
        </div>
 
    </mat-card-content>
</mat-card>
-->