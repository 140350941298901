<mat-card>
    <mat-card-header [matTooltip]="category.description !== undefined ? category.description[translate.currentLang] : null" matTooltipShowDelay="1000">
        <mat-card-title>
            {{category.name[translate.currentLang]}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-profil-question [question]="question" [answer]="profilCategory[question.id]" [enabledForm]="enabledForm" *ngFor="let question of category.questions"></app-profil-question>
    </mat-card-content>
</mat-card>
