<div *ngIf="selectedForm==null">
    <div class="row">
        <div class="col-11">
            <h1>
                <span>{{ "verifications.asked" | translate }}</span>
            </h1>
        </div>
    </div>

    <mat-grid-list cols="2" rowHeight="250px">
        <div *ngFor="let form of forms; let index = index;">
            <mat-grid-tile>
                <app-form-summary  (click)="setSelectedForm(form)" [form]=form [profileForm]=profile[forms[index].id]>
                </app-form-summary>
            </mat-grid-tile>
        </div>
    </mat-grid-list>
</div>

<app-profil-form *ngIf="selectedForm!=null" [form]="selectedForm" [profileForm]="profile[selectedForm.id]" [enabledForm]="false">
</app-profil-form> 