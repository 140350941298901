<mat-sidenav-container style="height: 100%;">
    <mat-sidenav opened mode="side">
        <mat-nav-list>
            <a mat-list-item (click)="changePage(-1);"  matTooltipPosition="right">
                <i class="fas fa-id-card fa-3x"></i><br>
            </a>
            <a mat-list-item (click)="changePage(0);" [matTooltip]="'mainpage.wallet' | translate" matTooltipPosition="right">
                <i class="fas fa-wallet fa-3x"></i><br>
            </a>
            <a mat-list-item (click)="changePage(1);" [matTooltip]="'mainpage.proofs' | translate" matTooltipPosition="right">
                <i class="fas fa-search fa-3x"></i><br>
            </a>
            <a *ngIf="isPatient()" mat-list-item (click)="changePage(2);" [matTooltip]="'mainpage.verifications' | translate" matTooltipPosition="right">
                <span matBadge="{{getVerificationsNumber()}}" matBadgeColor="warn">
                    <i class="fas fa-share fa-3x"></i><br>
                </span>
            </a>
            <a *ngIf="isPatient()" mat-list-item (click)="showAlerts($event)" [matTooltip]="'mainpage.notifications' | translate"
                matTooltipPosition="right">
                <span matBadge="{{getAlertNumber()}}" matBadgeColor="warn">
                    <img src="../../assets/pictos/notifications_active.svg">
                </span>
            </a>
            <a *ngIf="!isPatient()" (click)="showUsers()" [matTooltip]="'mainpage.changeuser' | translate"
                matTooltipPosition="right">
                <img src="../../assets/pictos/CHANGEMENT PATIENT.svg" style="margin: 10px;">
            </a>
        </mat-nav-list>
        <a mat-list-item href="/logout" routerLinkActive="active" class="logout" (click)="logout()">{{ "mainpage.deconnexion" | translate }}</a>
    </mat-sidenav>
    <div style="margin:20px;height: 100%;">
        <app-profil-form *ngIf="page == -1 && travelogue!== undefined" [form]="travelogueForm" [profileForm]="travelogue" [enabledForm]="enabled()">
        </app-profil-form>
        <app-wallet *ngIf="page == 0">
        </app-wallet>
        <app-proofs *ngIf="page == 1">
        </app-proofs>
        <app-verifications *ngIf="page == 2">
        </app-verifications>
        <!--
        <app-profil-form *ngIf="selectedForm!=null" [form]="selectedForm" [profileForm]="profile[selectedForm.id]"
            [enabledForm]="enabled()">
        </app-profil-form>
        -->
    </div>
</mat-sidenav-container>

<!--
<mat-sidenav-container style="height: 100%;">
    <mat-sidenav opened mode="side">
        <mat-nav-list>
            <a mat-list-item (click)="setSelectedForm(null)" [matTooltip]="'mainpage.mainpage' | translate" matTooltipPosition="right">
                <i class="fas fa-home fa-3x"></i><br>
            </a>
            <a mat-list-item (click)="showAlerts($event)" [matTooltip]="'mainpage.notifications' | translate" matTooltipPosition="right">
                <span matBadge="{{getAlertNumber()}}" matBadgeColor="warn">
                    <img src="../../assets/pictos/notifications_active.svg">
                </span>
            </a>
            <a *ngIf="!isPatient()" (click)="showUsers()"  [matTooltip]="'mainpage.changeuser' | translate"  matTooltipPosition="right">
                <img src="../../assets/pictos/CHANGEMENT PATIENT.svg" style="margin: 10px;">
            </a>
        </mat-nav-list>
        <mat-nav-list>
            <p *ngFor="let form of forms" style="text-align: center;">
                <button mat-fab matTooltip="{{getFormname(form)}}"  matTooltipPosition="right" (click)="setSelectedForm(form)">
                    <mat-icon>dashboard</mat-icon>
                </button>
            </p>
        </mat-nav-list>
        <a mat-list-item href="" class="logout" (click)="logout()">{{ "mainpage.deconnexion" | translate }}</a>
    </mat-sidenav>
    <div style="margin:20px;height: 100%;">
        <app-profile-summary *ngIf="selectedForm==null" [forms]="forms" [profile]="profile">
        </app-profile-summary>

        <app-profil-form *ngIf="selectedForm!=null" [form]="selectedForm" [profileForm]="profile[selectedForm.id]"
            [enabledForm]="enabled()">
        </app-profil-form>
    </div>
</mat-sidenav-container>
-->