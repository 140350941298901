import {HttpClientModule} from '@angular/common/http';
import {MyMaterialModule} from './material-module';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ProfilCategoryComponent } from './profil-category/profil-category.component';
import { ProfilQuestionComponent } from './profil-question/profil-question.component';
import { ProfilFormComponent } from './profil-form/profil-form.component';
import { UserListComponent, FilterPipe } from './user-list/user-list.component';
import { HomeComponent } from './home/home.component';
import { FormSummaryComponent } from './form-summary/form-summary.component';
import { MainComponent } from './main/main.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';
import { ThemeChartComponent } from './theme-chart/theme-chart.component';
import { ProfilThemeComponent } from './profil-theme/profil-theme.component';
import { AlertsComponent } from './alerts/alerts.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';

import {HttpClient} from '@angular/common/http';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { WalletComponent } from './wallet/wallet.component';
import { ProofsComponent } from './proofs/proofs.component';
import { VerificationsComponent } from './verifications/verifications.component';


@NgModule({
  declarations: [
    AppComponent,
    ProfilCategoryComponent,
    ProfilQuestionComponent,
    ProfilFormComponent,
    UserListComponent,
    HomeComponent,
    FormSummaryComponent,
    MainComponent,
    ProfileSummaryComponent,
    ThemeChartComponent,
    ProfilThemeComponent,
    FilterPipe,
    AlertsComponent,
    QrcodeComponent,
    WalletComponent,
    ProofsComponent,
    VerificationsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MyMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    QRCodeModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    })
  ],
  entryComponents: [
    ProfilThemeComponent,
    AlertsComponent,
    QrcodeComponent
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}