import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profil-theme',
  templateUrl: './profil-theme.component.html',
  styleUrls: ['./profil-theme.component.css']
})
export class ProfilThemeComponent implements OnInit {
  theme: any;
  profilTheme: any;
  enabledForm: any;
  sortedCategories: any = [];
  selectedCategory:any;
  selectedTab = 0;
  element = undefined;

  constructor(public dialogRef: MatDialogRef<ProfilThemeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public translate: TranslateService) { 
    this.theme = data.theme;
    this.profilTheme = data.profilTheme;
    this.enabledForm = data.enabledForm;
    this.selectedCategory = data.selectedCategory;

    this.theme.categories.forEach(category => {
      if (this.profilTheme[category.id] === undefined)
        this.profilTheme[category.id] = {};
    });

    for (let i = 0; i < this.theme.categories.length; i++) {
      this.sortedCategories.push(this.theme.categories[i]);
    }
  }

  ngOnInit() {
    if (this.selectedCategory !== undefined) {
      setTimeout(() =>{
        this.scrollToCategory();
      },1000);
      
    }
  }

  getCategoryName(cat) {
    return cat.name[this.translate.currentLang];
  }
  
  scrollToCategory() {
    var index = this.sortedCategories.indexOf(this.selectedCategory);
    this.selectedTab = index;
    var element = document.getElementById(""+index);
    element.scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
  }

  scroll(event) {
    var element = document.getElementById(""+event.index);
    element.scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
  }
}
