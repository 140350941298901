<div class="row">
    <div class="col-8">
        <h1>{{form.formName[translate.currentLang]}}</h1>
    </div>
    <div class="col-2">
        <button mat-fab color="primary" *ngIf="isPersonnalInformation()" (click)="save()" >
            <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isCredentialToIssue()" style="width:100%">
            <button mat-fab color="primary" (click)="issueCredential()" >
                <mat-icon>send</mat-icon>
            </button>
            <button mat-icon-button (click)="import()" style="margin-left: 20px;">
                <i class="fas fa-file-import fa-3x" ></i>
            </button>
        </div>
        <button mat-fab color="primary" *ngIf="isProofToVerify()" (click)="verifyProof()" >
            <mat-icon>search</mat-icon>
        </button>
        <div *ngIf="isVerificationToAccept()" style="width:100%">
            <button mat-fab color="primary" (click)="acceptVerification()">
                <i class="fas fa-thumbs-up"></i>
            </button>
            <button mat-fab color="warn" (click)="rejectVerification()" style="margin-left: 20px;">
                <i class="fas fa-thumbs-down" ></i>
            </button>
        </div>
    </div>
    <div class="col-2">
        <mat-form-field appearance="standard" style="width: 100%;">
            <mat-label>{{ "profileform.choixdate" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
</div>



<div class="themes-list" *ngIf="!isUniqueQuestion()">
    <div *ngFor="let theme of form.themes">
        <div class="row">
            <div class="col-9">
                <div class="theme-list-item mat-elevation-z6" (click)="selectedTheme(theme)">
                    <div class="row h-100">
                        <div class="col-2 my-auto ">
                            <div class="theme-list-item-icon w-50 mx-auto">
                                <img *ngIf="theme.picto == undefined" src="../../assets/pictos/BOUTON CHANGEMENT.svg">
                                <img *ngIf="theme.picto !== undefined" src="../../assets/pictos/{{theme.picto}}.svg">
                            </div>
                        </div>
                        <div class="col-10  my-auto theme-description-cell">
                            <div class="theme-list-item-content">
                                <h3>{{theme.name[translate.currentLang]}}</h3>
                                <div class="theme-description" *ngIf="theme.description !== undefined">
                                    {{theme.description[translate.currentLang]}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
            <div class="row h-100" class="centercontainer">
                <app-theme-chart id="chart" [form]=form [theme]=theme [profiltheme]=profileForm[theme.id]></app-theme-chart>
                <div id="doc">
                    <form #cartForm="ngForm" (ngSubmit)="onSubmit(cartForm, $event)" method="post" action="https://www.docautisme.com/index.php?lvl=search_segment&amp;action=segment_results&amp;id=10" target="_blank" style="width: 100%;height: 100%;">
                        <input type="hidden" value="1" name="look_ALL"><input type="hidden" name="look_AUTHPERSO_1#" id="look_AUTHPERSO_1#" value="1">
                        <input type="hidden" name="user_query" value="{{getDocAutismSearch(theme)}}">
                        <button mat-flat-button type="submit" style="height: 100%;"><img src="../../assets/pictos/doc.png"></button>
                    </form>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isUniqueQuestion()">
    <app-profil-question [question]="form.themes[0].categories[0].questions[0]" [answer]="getUniqueQuestion()"
        [enabledForm]="enabledForm"></app-profil-question>
</div>