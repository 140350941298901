import { Component, Input, OnChanges } from '@angular/core';
import { ProfilService } from '../profil.service';
import { UserService } from '../user.service';
import { CredentialService } from '../credential.service';
import { VerifyService } from '../verify.service';

import { VerificationsService } from '../verifications.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfilThemeComponent } from '../profil-theme/profil-theme.component';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profil-form',
  templateUrl: './profil-form.component.html',
  styleUrls: ['./profil-form.component.css']
})
export class ProfilFormComponent implements OnChanges {
  @Input() form: any = [];
  @Input() profileForm: any = [];
  @Input() enabledForm: any;

  date = new FormControl(new Date());

  constructor(private profilService: ProfilService,private verificationsService: VerificationsService, private verifyService: VerifyService, private userService: UserService, private credentialService: CredentialService,
    public dialog: MatDialog, private _snackBar: MatSnackBar, private spinnerService: NgxSpinnerService, public translate: TranslateService) { }

  ngOnChanges() {
    this.form.themes.forEach(theme => {
      if (this.profileForm[theme.id] === undefined)
        this.profileForm[theme.id] = {};
    });
  }

  selectedTheme(theme) {
    this.dialog.open(ProfilThemeComponent, { data: { theme: theme, profilTheme: this.profileForm[theme.id], enabledForm: this.enabledForm }, height: '90%', width: '90%', panelClass: 'theme-dialog' });
  }

  isUniqueQuestion() {
    if (this.form.themes.length == 1 && this.form.themes[0].categories.length == 1 && this.form.themes[0].categories[0].questions.length == 1)
      return true;
    return false;
  }

  getUniqueQuestion() {
    var theme = this.profileForm["0"];
    if (theme["0"] === undefined) {
      theme["0"] = {};
      theme["0"]["0"] = {};
    }

    return this.profileForm["0"]["0"]["0"];
  }

  isPersonnalInformation() {
    return (this.userService.getUser().job === "patient" && this.form.enabledFor === "patient");
  }

  isCredentialToIssue() {
    return (this.userService.getUser().job !== "patient" && this.form.version == undefined && this.form.enabledFor !== "patient");
  }

  isProofToVerify() {
    return (this.userService.getUser().job !== "patient" && this.form.version !== undefined);
  }

  isVerificationToAccept() {
    return (this.userService.getUser().job === "patient" && this.form.toAccept !== undefined);
  }

  getDocAutismSearch(theme) {
    if (this.form.version !== undefined) {
      return theme.description[this.translate.currentLang]+" "+theme.name[this.translate.currentLang];
    } else {
      return this.form.formName[this.translate.currentLang]+" "+theme.name[this.translate.currentLang];
    }

  }

  import() {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 2000);
  }

  issueCredential() {
    this.spinnerService.show();

    this.credentialService.issueCredential(this.userService.getUser(), this.userService.getSelectedPatient(), this.form, this.profileForm).subscribe(
      resp => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("profileform.credentialsent"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      },
      error => {
        console.log(error);
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("profileform.credentialerror"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });

  }

  save() {
    this.spinnerService.show();
    this.profilService.saveProfile(this.userService.getUser(), this.form, this.profileForm).subscribe(
      resp => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("profileform.saved"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("profileform.savederror"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  verifyProof() {
    this.spinnerService.show();
    this.verifyService.verifyCredential(this.userService.getUser(), this.userService.getSelectedPatient(), this.form.name+":"+this.form.version).subscribe(
      resp => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("formsummary.verifyasked"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("formsummary.verifyfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }



  acceptVerification() {
    this.spinnerService.show();
    this.verifyService.acceptVerify(this.userService.getUser(), this.form.verifyId).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.verifyaccepted"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.verificationsService.loadVerifications(this.userService.getUser());
        this.verificationsService.setSelectedForm(null);
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  rejectVerification() {
    this.spinnerService.show();
    this.verifyService.rejectVerify(this.userService.getUser(), this.form.verifyId).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.verifyrejected"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.verificationsService.loadVerifications(this.userService.getUser());
        this.verificationsService.setSelectedForm(null);
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  onSubmit(form: any, e: any): void {
    e.target.submit();
  }

}
