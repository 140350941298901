
<div class="container-fluid">

    <div class="row">

        <div class="col-4 signin">
            <select #langSelect (change)="languageChanged(langSelect.value)">
                <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
            </select>
            <div class="center-div white-font">
                <div class="big-font">{{ "home.dejainscrit" | translate }}</div>
                <br>
                <div class="italic-font">{{ "home.veuillezvousconnecter" | translate }}</div>
                <br>
                <div>
                    <a mat-button href="/login" routerLinkActive="active">
                        {{ "home.connect" | translate }}
                    </a>
<!--
                    <form (submit)="connect()" action="#">
                        <mat-error *ngIf="loginError !== undefined">
                            {{loginError}}
                        </mat-error>
                        <mat-form-field appearance="outline">
                            <input matInput type="email" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input matInput type="password" [(ngModel)]="password"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>

                        <button mat-button type="submit">
                            {{ "home.connect" | translate }}
                        </button>
                        <a href="/login" routerLinkActive="active">Abcd</a>

                    </form>
                    -->

                </div>
            </div>
        </div>
        <div class="col-8 signup">
            <div class="center-div blue-font">
                <form (submit)="signup()" action="#">
                    <div class="row">
                        <div class="col-12">
                            <mat-error *ngIf="signupError !== undefined">
                                {{signupError}}
                            </mat-error>
                        </div>
                        <div class="col-6">
                            <div>
                                {{ "home.nom" | translate }}
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput [(ngModel)]="signupUser.lastname" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <div>
                                {{ "home.prenom" | translate }}
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput [(ngModel)]="signupUser.firstname" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div>
                                {{ "home.role" | translate }}
                            </div>
                            <mat-form-field  appearance="outline">
                                <mat-select [(value)]="signupUser.job" >
                                    <mat-option *ngFor="let org of organizations" [value]="org._id">
                                        {{getOrganizationLabel(org)}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div>
                                {{ "home.mail" | translate }}
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput type="email" [(ngModel)]="signupUser.email" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div>
                                {{ "home.motdepasse" | translate }}
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput type="password" [(ngModel)]="signupUser.password" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-12" style="text-align: center;">
                            <button mat-button type="submit" class="signup-button">
                                {{ "home.inscrire" | translate }}
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
