import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private http: HttpClient) { }

  createConnectionOffer(user, patient) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    var message =  { agentToConnect : patient.agentId };
    return this.http.post('connectionapi/connect', message, httpOptions);
  }


  getConnectionProposal(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('connectionapi/connect', httpOptions);
  }

  acceptConnection(user, connectionOffer) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    return this.http.put('connectionapi/connect', {offer: connectionOffer}, httpOptions);
  }


  rejectConnection(user, connectionOffer) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };

    return this.http.post('connectionapi/rejectConnection', {offer: connectionOffer}, httpOptions);
  }

  getConnectedPatients(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token
      })
    };
    return this.http.get('connectionapi/connected', httpOptions);
  }
}
