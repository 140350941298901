import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { ConnectionService } from '../connection.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CredentialService } from '../credential.service';
import { ProfilService } from '../profil.service';
import { VerifyService } from '../verify.service';
import { TranslateService } from '@ngx-translate/core';
import { WalletService } from '../wallet.service';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  connectionOffers: any = [];
  credentialOffers: any = [];
  verifyDemands: any = [];

  verifyDemandsAttributes = {};

  constructor(public dialogRef: MatDialogRef<AlertsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private profilService: ProfilService, private walletService: WalletService, private verifyService: VerifyService, private spinnerService: NgxSpinnerService,
    private userService: UserService, private connectionService: ConnectionService, private credentialService: CredentialService, private _snackBar: MatSnackBar, public translate: TranslateService) {
    this.connectionOffers = data.connectionOffers;
    this.credentialOffers = data.credentialOffers;
    this.verifyDemands = data.verifyDemands;
  }

  ngOnInit() {
  }

  getAlertNumbers() {
    return this.connectionOffers.length + this.credentialOffers.length + this.verifyDemands.length;
  }

  acceptConnectionOffer(connectionOffer) {
    this.spinnerService.show();
    this.connectionService.acceptConnection(this.userService.getUser(), connectionOffer).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.connectionacceped"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.connectionOffers.splice(this.connectionOffers.indexOf(connectionOffer), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  rejectConnectionOffer(connectionOffer) {
    this.spinnerService.show();
    this.connectionService.rejectConnection(this.userService.getUser(), connectionOffer).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.connectionrejected"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.connectionOffers.splice(this.connectionOffers.indexOf(connectionOffer), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  acceptCredentialOffer(credentialOffer) {
    this.spinnerService.show();
    this.credentialService.acceptCredential(this.userService.getUser(), credentialOffer).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.credentialaccepted"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
//        this.profilService.initializeFormFromCredential(credentialOffer.offer);
          this.walletService.initializeFormFromCredential(credentialOffer.schema_name, credentialOffer.preview, credentialOffer.timestamps.updated);
          this.credentialOffers.splice(this.credentialOffers.indexOf(credentialOffer), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  rejectCredentialOffer(credentialOffer) {
    this.spinnerService.show();
    this.credentialService.rejectCredential(this.userService.getUser(), credentialOffer).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.credentialrejected"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.credentialOffers.splice(this.credentialOffers.indexOf(credentialOffer), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

/*
  acceptVerifyDemand(verifyDemand) {
    this.spinnerService.show();
    let attributes  = Object.assign({}, this.verifyDemandsAttributes[verifyDemand.id]);

    this.verifyService.acceptVerify(this.userService.getUser(), verifyDemand, attributes).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.verifyaccepted"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.verifyDemands.splice(this.verifyDemands.indexOf(verifyDemand), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }

  rejectVerifyDemand(verifyDemand) {
    this.spinnerService.show();
    this.verifyService.rejectVerify(this.userService.getUser(), verifyDemand).subscribe(
      (resp: any) => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.verifyrejected"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
        this.verifyDemands.splice(this.verifyDemands.indexOf(verifyDemand), 1);
        if (this.getAlertNumbers() == 0) {
          this.dialogRef.close();
        }
      },
      error => {
        this.spinnerService.hide();
        this._snackBar.open(this.translate.instant("alert.requestfailed"), "", {
          duration: 2000,
          verticalPosition: "top"
        });
      });
  }
*/

  getVerifyAttibutes(verifyDemand) {
    if (this.verifyDemandsAttributes[verifyDemand.id] === undefined) {
      let attributes = {};
      let keys = Object.keys(verifyDemand.proof_request.requested_attributes) ;
      keys.forEach(element => {
        if (verifyDemand.proof_request.requested_attributes[element].restrictions === undefined)
          attributes[verifyDemand.proof_request.requested_attributes[element].name] = true;
      });
      this.verifyDemandsAttributes[verifyDemand.id] = attributes;
    }

    return Object.keys(this.verifyDemandsAttributes[verifyDemand.id]);
  }

  getCredentialOfferLabels(credentialOffer) {
    return {label:this.getFormLabel(credentialOffer.schema_name), by:this.getOrganizationLabel(credentialOffer.connection.remote.name)};
  }
  
  getFormLabel(name) {
    for (let k = 0; k < this.profilService.getForms().length; k++) {
      if (this.profilService.getForms()[k].name == name) {
        return this.profilService.getForms()[k].formName[this.translate.currentLang];
      }
    }
    return name;
  }

  getOrganizationLabel(name) {
      return this.profilService.getOrganization(name).label[this.translate.currentLang];
  }
}
