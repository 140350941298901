import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { VerificationsService } from '../verifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
  styleUrls: ['./verifications.component.css']
})
export class VerificationsComponent implements OnInit {
  forms: any;
  profile: any;
  user: any;
  selectedForm: any = null;
  patient : any = null;

  constructor(private verificationsService: VerificationsService,private userService: UserService, private spinnerService: NgxSpinnerService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.spinnerService.show();

    this.profile = this.verificationsService.getVerifications();
    this.user = this.userService.getUser();
    this.patient = this.userService.getSelectedPatient();

    this.verificationsService.verificationsEvent.subscribe(prof => {
      this.spinnerService.hide();
      this.profile = prof;
      this.forms = this.verificationsService.verificationSchemas;
      this.forms.forEach(form => {
        if (this.profile[form.id] === undefined || JSON.stringify(this.profile[form.id]).indexOf("answer") === -1) {
            this.profile[form.id] = {};
          }
      });
    });

    this.verificationsService.loadVerifications(this.user);

        
    this.verificationsService.selectedVerificationsFormEvent.subscribe(form => {
      this.selectedForm = form;
    });
  }

  setSelectedForm(form) {
    this.selectedForm = form;
  }
}
