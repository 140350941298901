import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../user.service';
import * as d3 from "d3";

@Component({
  selector: 'app-theme-chart',
  templateUrl: './theme-chart.component.html',
  styleUrls: ['./theme-chart.component.css']
})
export class ThemeChartComponent implements OnInit {
  @Input() theme: any;
  @Input() form: any;
  @Input() profiltheme: any;

  public tooltip: any;
  score = "";

  constructor(private userService: UserService) {
    this.tooltip = d3.select(".chart-tooltip");
  }

  ngOnInit() {
    if (this.form.name === "asperger-auto-diagnostic") {
      this.userService.score(this.profiltheme).subscribe((resp: any) => {
        this.score = resp.response.result.response;
      },
        error => {
          console.log(error);
        });
    }
  }

  ngAfterViewInit() {

    var width = 150;
    var height = 150;

    var radius = Math.min(width, height) / 2 - 10;

    // append the svg object to the div called 'my_dataviz'
    var svg = d3.select("#form_" + this.form.id + "_theme_" + this.theme.id)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    var val = Math.floor(Math.random() * 100);
    var val2 = Math.floor(Math.random() * 100);
    var data: any = [{ "key": "a", "value": Math.max(val, val2) }, { "key": "b", "value": 100 - Math.max(val, val2) }];
    var data2: any = [{ "key": "a", "value": Math.min(val, val2) }, { "key": "b", "value": 100 - Math.min(val, val2) }];
    this.createPie(svg, data, "#6982E9", radius);
    this.createPie(svg, data2, "#FE949B", radius - 20);
  }

  createPie(svg, data, color, radius) {
    // Compute the position of each group on the pie:
    var pie = d3.pie().sort(null).value(function (d: any) { return d.value; });
    var data_ready = pie(data);

    var arc: any = d3.arc().innerRadius(radius - 10).outerRadius(radius)
    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('whatever')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr("stroke", "black")
      .on('mouseover', this.mouseOver.bind(this))
      .on('mousemove', this.mouseMove.bind(this))
      .on('mouseout', this.mouseOut.bind(this))
      .style("stroke-width", "0px")
      .style("opacity", function (d: any) { if (d.data.key == 'a') return 1; else return 0; })
      .style('fill', color).transition().duration(2000)
      .attrTween('d', function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d)
        }
      });

  }

  mouseOver(event, d) {
    this.tooltip
      .style('display', 'inline-block')
      .style('position', 'absolute')
      .text(d.data.value)
      ;
  }

  mouseMove(event, d) {
    this.tooltip
      .text(d.data.value)
      .style('left', (event.pageX + 10) + "px")
      .style('top', (event.pageY + 10) + "px");
    /*
    this.tooltip
      .text(d.data.value)
      .style('left', ((<any>d3["event"]).pageX + 10) + "px")
      .style('top', ((<any>d3["event"]).pageY + 10) + "px");*/
  }

  mouseOut() {
    this.tooltip
      .style('display', 'none')
      ;
  }
}
